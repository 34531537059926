<template>
  <div>
    <section>
      <article class="thank_you_article">
        <div class="text-center">
          <h1>Thank you.</h1>
          <el-result
            icon="success"
            :title="`Your quote has been sent to `+ $route.query.email +`. Please check `"
            subTitle="We can't wait to meet you"
          >
            <template slot="extra">
              <el-button
                type="success"
                @click="$router.push({path: '/safari-packages'})"
              >
                Continue To Tour Packages <i class="el-icon-d-arrow-right"></i>
              </el-button>
            </template>
          </el-result>
          <!-- <p>Click here to book your appointment online instantly. It only takes a minute.</p> -->
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.thank_you_article {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thank_you_article h1 {
  font-size: 2.5em;
  font-weight: 600;
  text-transform: capitalize;
}
</style>